import {createSelector} from "reselect";
import {LoadingState} from "constants/redux";
import {convertErrorIntoErrorMessage} from "../helpers";
import {AppState} from "redux/AppState";
import {UserAccountPermissions, UserRole} from "@sense-os/goalie-js";
import {Contact} from "../../contacts/contactTypes";
import {appAccess, orgAccess} from "../helpers/access";

const getAuthState = (state: AppState) => state.auth;
const getLoginLoadingState = createSelector(getAuthState, (state) => state.loginLoadingState);
const getRegistrationLoadingState = createSelector(getAuthState, (state) => state.registrationLoadingState);
const getError = createSelector(getAuthState, (state) => state.error);
export const getAuthUser = createSelector(getAuthState, (state) => state.user);

/**
 * Sometimes, we need to put the authUser into a list of Contact.
 * Here's the helper to help make a shadow Contact for current auth user.
 */
export const getAuthUserAsContact = createSelector(
	getAuthUser,
	(authUser): Contact => ({
		id: authUser.id,
		fullName: authUser.fullName,
		firstName: authUser.firstName,
		hashId: authUser.hashId,
		image: authUser.imageUrl,
		lastName: authUser.lastName,
		organization: {...authUser.organization, logo: null},
		role: UserRole.THERAPIST,
		bio: null,
		birthDate: null,
		connectedSince: null,
		email: null,
		gender: null,
		location: null,
		registerDate: null,
		type: null,
	}),
);

/**
 * Returns true if portal is trying to logging in the user
 */
export const isLoggingIn = createSelector(getLoginLoadingState, (state) => state === LoadingState.LOADING);
/**
 * Returns true if portal is trying to logging in the user
 */
export const isRegistering = createSelector(getRegistrationLoadingState, (state) => state === LoadingState.LOADING);
/**
 * Returns error message key by evaluating `error` object from state
 */
export const getAuthErrorMessage = (state: AppState) => {
	return convertErrorIntoErrorMessage(getError(state));
};

export const getOwnId = createSelector(getAuthState, (state) => (state.user ? state.user.id : -1));

/**
 * Check whether user is logged in.
 */
export const isLoggedIn = createSelector(getAuthState, (state) => !!state.user);

export const getUserOrganization = (state: AppState) => state.auth.user?.organization;

export const isAcceptedToOrganization = (state: AppState) => getUserOrganization(state)?.isAccepted;

/**
 * Get user's role. Usually to differentiate between client and therapist.
 */
export const getUserRoles = (state: AppState): UserAccountPermissions[] => state.auth.user?.roles;

/** Returns true if logged in user is a client */
export const isPatient = (state: AppState): boolean => {
	const userRoles: UserAccountPermissions[] = getUserRoles(state);
	return !!userRoles?.find((role) => role === UserAccountPermissions.PATIENT);
};

/** Returns true if logged in user is a therapist */
export const isTherapist = (state: AppState): boolean => {
	const userRoles: UserAccountPermissions[] = getUserRoles(state);
	return !!userRoles?.find((role) => role === UserAccountPermissions.THERAPIST);
};

/** Returns true if logged in user is a therapist and accepted to the organization */
export const isAcceptedTherapist = (state: AppState) => isTherapist(state) && isAcceptedToOrganization(state);

/** User can access org mode if they are admin. */
export const canAccessOrgMode = createSelector(
	(state: AppState) => state.auth.user?.roles,
	(permissions) =>
		!!permissions &&
		!!permissions?.find(
			(permission) =>
				!!orgAccess.find((access) => {
					return access === permission;
				}),
		),
);

/**
 * User can access app mode if their roles is in appAccess
 * Or, when they have an empty permissions (assuming they are user who just register).
 */
export const canAccessAppMode = createSelector(
	(state: AppState) => state.auth.user?.roles,
	(permissions) => {
		if (permissions?.length === 0) {
			return true;
		}

		return !!permissions?.find(
			(permission) =>
				!!appAccess.find((access) => {
					return access === permission;
				}),
		);
	},
);

export const getChangePasswordLoadingState = (state: AppState) => getAuthState(state).changePasswordLoadingState;

export const isLoginBlocked = (state: AppState) => getAuthState(state).isLoginBlocked;
export const getLoginTwoFAState = (state: AppState) => getAuthState(state).loginTwoFA;
export const getLoginTwoFACodeAttemptCount = (state: AppState) => getLoginTwoFAState(state).codeAttemptCount;
export const getLoginTwoFARecoveryCodeAttemptCount = (state: AppState) =>
	getLoginTwoFAState(state).recoveryCodeAttemptCount;

export const getPasswordResetTwoFAState = (state: AppState) => getAuthState(state).passwordResetTwoFA;
export const getPasswordResetTwoFACodeAttemptCount = (state: AppState) =>
	getPasswordResetTwoFAState(state).codeAttemptCount;
export const getPasswordResetTwoFARecoveryCodeAttemptCount = (state: AppState) =>
	getPasswordResetTwoFAState(state).recoveryCodeAttemptCount;

export const getEmailVerificationStatusFetchState = (state: AppState) =>
	getAuthState(state).emailVerificationLoadingState;

export const getEmailVerificationStatus = (state: AppState) => getAuthState(state).user?.account?.emailVerified;
